<template>
  <div class="home">
    <video class="video-back" loop autoplay muted>
      <source src="@/assets/fondo.mp4" type="video/mp4">
    </video>
    <v-container style="height: 100vh;   z-index: 1;" class="wrap-title">
      <v-row>
        <v-col cols="12" sm="12" lg="6" md="6">
          <h1 class="mt-12 title-home">Your mental health is important Sensa gives you the tools to help you improve it
          </h1>
          <v-btn color="#2853bc" dark rounded large class="mt-2">Contáctanos</v-btn>
        </v-col>
        <v-col cols="12" sm="12" lg="6" md="6" style="position: relative;">
          <img src="@/assets/2.png" class="trama-01" alt="" srcset="">

          <video class="mt-2 display" loop autoplay muted>
            <source src="@/assets/fondo.mp4" type="video/mp4">
          </video>

        </v-col>
      </v-row>

    </v-container>
    <v-container style=" z-index: 1; color: #fff; position: relative;">

      <h1 class="text-center mb-6"> Display any type of content
        on your screens quickly and easily</h1>
      <v-row>
        <v-col cols="4" v-for="i in 6">
          <v-card class="card-info" dark>
            <v-card-title>
              Show Your Media on Screen in Seconds
            </v-card-title>
            <v-card-text>
              Harness the power of videos & images and make your message memorable. You can also display documents, web
              pages, YouTube & livestream videos. Browse our free stock image and video galleries.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <section class="services py-12">
      <v-container>
        <v-row align-center justify-center>
          <v-col cols="6">
            <v-img style="border-radius: 20px;" src="https://fontawesome.com/social/face-smile-wink?f=&s="></v-img>
          </v-col>
          <v-col cols="6" class="flex-center-row colum-direction">
            <h1>Services</h1>
            <p> Harness the power of videos & images and make your message memorable. You can also display documents, web
              pages, YouTube & livestream videos. Browse our free stock image and video galleries.</p>
          </v-col>
        </v-row>
       
        <div class="clients">
          <img class="gris" v-for="(i,index) in brands" :key="index" :src="i" height="50" alt="" srcset="">
         

        </div>
      </v-container>
    </section>
    <section class="proyects">

    </section>
  </div>
</template>

<script>


export default {
  name: 'Home',

  components: {

  },
  data(){
    return{
      brands:[
       'https://beanar.io/img/logo-01.b87edaf0.png',
       'https://beanar.io/img/logo-01.b87edaf0.png',
       'https://beanar.io/img/logo-04.cbd21d98.png'
      ]
    }
  }
}
</script>
<style lang="scss">
.home {


  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-back {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  object-fit: cover;


}

.title-home {
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 3.5rem;
  color: rgb(255, 255, 255);
}

.card-info {
  border-radius: 1em !important;
  box-shadow: 0px 10px 10px rgba(74, 74, 74, 0.088) !important;
  background-color: rgb(8, 2, 47) !important;
}

.wrap-title {
  display: flex;
  justify-content: center;
  align-items: center;

}

html[data-scroll="0"] {
  .display {
    width: 100%;
    height: 300px;
    background-color: rgb(166, 213, 254);
    border-radius: 20px;

    transform: scale(1.5, 1.5) translateX(30%);

  }
}

.display {
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 300px;
  background-color: rgb(166, 213, 254);
  border-radius: 20px;
  border: solid 10px #000;
  object-fit: cover;
  transform: translateX(0%);
}

.trama-01 {
  width: 100%;
  position: absolute;
  left: -10%;
  top: 0%;
}

.flex-center-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;

}

.colum-direction {
  flex-direction: column;
}

.services {

  width: 100%;
  background-color: #fff !important;
  z-index: 2;
  margin-top: 50px;
}
.clients{
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.gris {
  
    filter: grayscale(100%);
    transition: .3s ease-in-out;
    &:hover{
      filter: grayscale(0);
    }
}


</style>