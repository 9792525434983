<template>
  <v-app>


    <v-main>
      <!-- <video class="video-back" loop autoplay muted>
        <source src="@/assets/fondo.mp4" type="video/mp4">
      </video> -->
      <!-- <navBar /> -->

      <router-view  />
    
      
    </v-main>
  </v-app>
</template>

<script>
import navBar from './components/navBar.vue';
export default {
  name: 'App',
  components: {
    navBar
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
// .video-back {
//   position: fixed;
//   left: 0;
//   top: 0;
//   height: 100%;
//   z-index: -1;

// }
</style>