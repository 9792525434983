<template>
  <div class="nav-app">
    <v-container class="flex-betwen">
      <img src="@/assets/logo-01.svg"  class="logo" alt="" srcset="">
      <v-spacer>

      </v-spacer>
      <ul class="ul-list">
        <li>
          <router-link :to="{ path: '/home', }">Inicio</router-link>
        </li>
        <li>
          <router-link :to="{ path: '/home', }">Sobre nosotros</router-link>
        </li>
        <li>
          <router-link :to="{ path: '/home', }">Servicios</router-link>
        </li>
 
      </ul>

      <v-btn class="primary" color="primary" rounded>
        Contactanos
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
  }),
}
</script>

<style lang="scss">
html[data-scroll="0"] {
  .nav-app {
    display: flex;
    width: 100%;
    z-index: 100 !important;
    position: fixed;
    justify-content: space-between;
    background-color: #ffffff00;

  }
  .logo{
    height: 80px;
  }
}
.logo{
  height: 50px;
  transition: 0.3s;
}
.nav-app {
  transition: 0.3s;
  display: flex;
  width: 100%;
  background-color: #2853bc;
  z-index: 100 !important;
  position: fixed;
  
  justify-content: space-between;


}


.flex-betwen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ul-list {
  list-style: none;
  overflow: hidden;

  li {
    float: left;
    margin-right: 15px;

  }
}

.v-application a {
  color: #1a1a1a;
  text-decoration: none;
  font-size: 14px;
}
</style>